import axios from '@/axios'
const route = 'register/social_charges'
export default {

    getAll() {
        return axios.get(route)
    },

    updateOrStore(social_charges) {
        return axios.post(route + '/update_or_store', social_charges)
    },

}
